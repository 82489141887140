import React from "react"
import Image from "../elements/Image";
import { ModelCard, ModelCardImg, ModelCardTitle, ModelCardSubtitle} from "../home/home.styles";

const ModelsCard = ({title, subtitle, image, color, black, grid}) => (
  <ModelCard black={black} className={grid === 5 ? `col-5` : (grid === 3 && `col-3`)}>
    <ModelCardImg>
      <Image fileName={image} alt={title}/>
    </ModelCardImg>
    <ModelCardTitle color={color}>{title}</ModelCardTitle>
    <ModelCardSubtitle>{subtitle}</ModelCardSubtitle>
  </ModelCard>
)

export default ModelsCard