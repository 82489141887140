import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints.styles";

export const ModelsBlock = styled.div`
  margin-bottom: 4rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  
  ${breakpoints('phone') `
    flex-direction: column;
    align-items: center;
  `} 
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100vw;
    height: 4px;
    background-color: ${props => props.black ? 'black' : '#E3E3E3'};
    transform: translateX(-50%);
    
    ${breakpoints('phone') `
      background-color: transparent;
    `} 
  }
`;

export const ModelCard = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  width: calc(25% - 2rem);
  position: relative;
  padding-bottom: 5rem;
  
  ${breakpoints('phone') `
    width: 24rem !important;
  `} 
  
  &.col-3{
    width: calc(33.3% - 2rem);
  }
  
  &.col-5 {
    width: calc(20% - 2rem);
  }
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: ${props => props.black ? 'black' : '#E3E3E3'};
    transition: background-color .3s;
  }
  
  &:hover:before {
    background-color: ${props => props.theme.colors.primary};
  } 
`;

export const ModelCardImg = styled.div`
   width: 100%;
   margin-bottom: 4rem;
   
   ${breakpoints('tabPort') `
    margin-bottom: 2rem;
  `} 
   
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ModelCardTitle = styled.h4`
  color: ${props => props.color ? props.color : 'black'};
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.6rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  transition: color .3s;
  
  ${breakpoints('tabPort') `
    font-size: 2.2rem;
  `} 
  
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export const ModelCardSubtitle = styled.h4`
  color: ${props => props.theme.colors.text};
  font-size: 1.6rem;
  font-weight: 200;
  text-align: center;
  text-transform: uppercase;
  margin-top: 1rem;
`;

export const RamBlock = styled.div`
  background-color: #131415;
  padding: 2px 0 4rem;
`;

export const DiscoverBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const DiscoverImage = styled.div`
  width: 39.6%;
  overflow: hidden;

  &:nth-of-type(2) {
    width: 46%;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
 
  &:not(:last-of-type) .wrapper:before {
    width: 5px;
    height: 30vw;
    transform: skew(22deg);
    bottom: 0;
    right: 18%;
    z-index: 10; 
  }
  
  &:not(:first-of-type) .wrapper:after {
    width: 5px;
    height: 14vw;
    transform: skew(22deg);
    top: 0;
    left: 10%;
    z-index: 10; 
  }
  
  &:nth-of-type(2) .wrapper:before {
    right: 15%;
  }
  
  &:nth-of-type(2) .wrapper:after {
    left: 8.5%;
  }
  
  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    
    &:before, 
    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      background-color: white; 
    }
  }
  
  img {
    width: 100%;
    height: 100%;
  }
`;

export const DodgeBlock = styled.div`
  padding: 2px 0 4rem;
`;

export const GridRow = styled.div`
  width: 100%;
  height: 24.3vw;
  max-height: 52rem;
  position: relative;
  
  ${breakpoints('phone') `
    max-height: unset;
    height: auto;
  `} 
  
  .container {
    display: flex;
    justify-content: ${props => props.alignLeft ? 'flex-start' : 'flex-end'};
    
    ${breakpoints('phone') `
      flex-direction: column;
    `} 
    
    &.mob-reverse {
    ${breakpoints('phone') `
        flex-direction: column-reverse;
      `} 
    }
  }
  
  .line {
    width: 10rem;
    height: 1px;
    background-color: ${props => props.theme.colors.primary};
    margin-bottom: 2rem;
  }
`;

export const GridImage = styled.div`
  width: 60vw;
  height: 24.3vw;
  max-width: 120rem;
  max-height: 52rem;
  position: absolute;
  left: ${props => props.alignLeft ? 'unset' : '0'};
  right: ${props => props.alignLeft ? '0' : 'unset'};
  margin-right: ${props => props.alignLeft ? '0' : '4rem'};
  
  ${breakpoints('phone') `
    position: unset;
    margin-bottom: 4rem;
    margin-right: 0;
    width: 100%;
    height: auto;
    max-height: unset;
  `} 
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const GridContent = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  
  ${breakpoints('phone') `
    width: 100%;
  `} 
`;

export const GridTitle = styled.h3`
  color: black;
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.4rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

export const GridText = styled.div`
  color: #808080;
  font-size: 1.4rem;
  line-height: 2.4rem;
`;

export const AboutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E8E8E8;
  margin: 4rem 0;
`;

export const AboutText = styled.div`
  color: #252525;
  font-size: 2rem;
  line-height: 2.7rem;
  text-align: center;
  margin-bottom: 4rem;
  
  ${breakpoints('phone') `
    width: 80vw;
    font-size: 1.6rem;
  `} 
`;

export const Video = styled.div`
  margin-bottom: 8rem;
  
  iframe {
    width: 100%;
    height: 49vw;
  }
`;