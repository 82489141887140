import styled from "styled-components";
import { breakpoints } from "../../styles/breakpoints.styles";

export const BlockGrey = styled.div`
  width: 100%;
  background-color: #E3E3E3;
  padding: 2px 0 4rem;
  margin-top: 4rem;
  
  ${breakpoints('tabPort') `
     padding-top: 4rem
  `}
`;

export const BlockRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4rem;
  
  ${breakpoints('tabLand') `
     justify-content: space-evenly;
  `}
`;

export const BlockCard = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.grid === 4 ? '23%' : '32%'};
  min-width: 22rem;
  
  .gatsby-image-wrapper {
    margin-bottom: 2rem;
  }
  
  &.card-2 { width: 48%; }
  &.card-5 { width: 18%; }
  
  ${breakpoints('tabLand') `
    width: 48%;
    margin-bottom: 3rem;
  `} 
  ${breakpoints('phone') `
    width: 30rem;
    margin-bottom: 3rem;
  `} 
`;

export const BlockCardText = styled.p`
  color: ${props => props.theme.colors.text};
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: ${props => props.align ? props.align : 'left'};
  
  ${breakpoints('tabPort') `
    text-align: center
  `} 
`;

export const BlackGrid = styled.div`
  width: 100%;
  background-color: #131415;
  padding: 2px 0 4rem;
  margin-top: 4rem;
  position: relative;
  
  &.first {
    margin-top: -8rem;
    padding: 7rem 0;
  }
`;

export const BlockRow2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  
  ${breakpoints('tabLand') `
     justify-content: space-evenly;
  `}
  
  ${breakpoints('tabPort') `
     flex-direction: column;
  `}
  
  &.reverse {
    flex-direction: row-reverse;
    height: 20vw;
    
    ${breakpoints('tabPort') `
       flex-direction: column;
       height: auto
    `}
  }
`;

export const BlockRowContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 48%;
  
  ${breakpoints('tabPort') `
     width: 100%;
     margin-bottom: 2rem;
  `}
`;

export const BlockRowImage = styled.div`
  width: 48%;
  
  &.outside {
    width: 64%;
    margin-right: 4rem;
    transform: translateY(-4rem);
     
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  
  ${breakpoints('tabPort') `
     width: 100%
  `}
  
  &.wide {
    position: absolute;
    left: 0;
    width: 49vw;
    height: 20vw;
    
    ${breakpoints('tabPort') `
      position: unset;
      width: 100%
    `}
  }
`;

export const BlockRowImage2 = styled.div`
  width: 70%;
  margin-right: 3rem;
`;


export const BlockTitle = styled.div`
  color: ${props => props.color ? props.color : 'black'};
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.6rem;
  font-weight: 500;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-align: ${props => props.align ? props.align : 'left'};
  
  ${breakpoints('tabPort') `
    font-size: 2.2rem;
    text-align: center;
  `} 
`;

export const BlockText = styled.div`
  color: ${props => props.theme.colors.text};
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: ${props => props.align ? props.align : 'left'};
`;

export const RowCapacity = styled.div`
  display: flex;
  justify-content: space-between;
  
  ${breakpoints('phone') `
    flex-direction: column;
    align-items: center;
  `} 
`;

export const CardCapacity = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  
  .gatsby-image-wrapper {
    margin-bottom: 4rem;
  }
  
  ${breakpoints('phone') `
     width: 30rem;
     margin-bottom: 3rem;
  `} 
`;

export const ImgPrecise = styled.div`
  position: relative;
  z-index: 10;
`;

export const BlockTech = styled.div`
  background-color: #131415;
  padding: 14rem 0 8rem;
  transform: translateY(-13.5rem);
`;

export const RowTech = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -20rem;
  
  ${breakpoints('phone') `
    flex-direction: column;
    align-items: center;
  `} 
`;

export const RowSound = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  
  ${breakpoints('phone') `
    flex-direction: column;
    align-items: center;
  `} 
`;

export const CardSound = styled.div`
  display: flex;
  flex-direction: column;
  width: 46%;
  
  ${breakpoints('phone') `
     width: 100%;
  `} 
`;

export const BlockGrey2 = styled.div`
  width: 100%;
  background-color: #E3E3E3;
  padding: 2px 0 20rem;
  margin: 4rem 0 24rem;
  position: relative;
  
  ${breakpoints('bigDesktop') `
    padding: 2px 0 26rem;
     margin: 4rem 0 30rem;
  `}
  
  .image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

export const RowSecurity = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4rem;
  
  ${breakpoints('tabLand') `
     justify-content: space-evenly;
  `}
  
  ${breakpoints('phone') `
    flex-direction: column;
    align-items: center;
  `} 
`;

export const CardSecurity = styled.div`
  display: flex;
  flex-direction: column;
  width: 18%;
  
  ${breakpoints('tabLand') `
     width: 30%;
     margin-bottom: 3rem;
  `} 
  
  ${breakpoints('phone') `
     width: 100%;
  `} 
`;

export const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  
  ${breakpoints('phone') `
     width: 100%;
     margin-bottom: 3rem;
  `} 
`;

export const RowAngle = styled.div`
  display: flex;
  background-color: #E3E3E3;
  width: 100%;
  position: relative;
  margin-top: 8rem;
  
  img {
    position: absolute; 
    bottom: 0;
    left: 0;
    width: 60%;
    
    ${breakpoints('tabPort') `
      position: relative; 
      width: 100%;
      height: auto;
    `} 
  }
  
  .content {
    font-family: ${props => props.theme.fonts.heading};
    font-size: 2.2rem;
    line-height: 36px;
    width: 36rem;
    padding: 14rem 0;
    margin-left: 60%;
    
    ${breakpoints('tabPort') `
      background-color: #E3E3E3;
      margin-left: 0;
      width: 100%;
      padding: 8rem 2rem;
    `} 
    
    ${breakpoints('phone') `
      font-size: 1.8rem;
    `} 
  }
  
  ${breakpoints('tabPort') `
    display: flex;
    flex-direction: column;
    background-color: white;
  `} 
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
  
  ${breakpoints('tabPort') `
    justify-content: space-evenly;
  `}
`;

export const RowCard = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  margin-bottom: 2rem;
  
  .gatsby-image-wrapper {
    margin-bottom: 3rem;
  }
`;

export const CardTitle = styled.div`
  font-family: ${props => props.theme.fonts.heading};
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.2rem;
`;