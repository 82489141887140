import React from "react";
import Layout from "../../components/layout";
import Image from "../../components/elements/Image";
import {
  ImageAngle,
  ModelsHeader,
  Text,
  CardTitle,
  CardLine,
  CardText,
  Card, CardImg, CardContent, CardList
} from "../../components/models/models.styles";
import { BlockGrey, BlockRow, BlockCard, BlockCardText, BlackGrid, BlockRowContent, BlockRowImage, BlockTitle, BlockText, RowAngle } from "../../components/models/charger.styles"
import { Title } from "../../styles/typography.styles";
import { Row } from "../../components/home/home.styles";
import ModelsCard from "../../components/models/models-card";
import * as DodgeJson from '../../data/dodge.json';

const Charger = () => {
  const charger  = DodgeJson.default.filter(item => item.model === "charger");

  return (
    <Layout title={'Dodge Charger'}>
      <ModelsHeader>
        <Image fileName={'charger_banner@2x.png'} alt={"Dodge Charger"} />
      </ModelsHeader>

      <main>
        <div className={`container`}>
          <Title>DODGE CHARGER TRIM</Title>

          <Row>
            <ModelsCard grid={3} title="DODGE CHARGER GT"
                        image="Charger-GT-PBM-landscape_thumb@2x.jpg"
            />
            <ModelsCard grid={3} title="DODGE CHARGER SCAT PACK WIDEBODY"
                        image="Charger-Scat-pack-PFQ-landscape_thumb@2x.jpg"
            />
            <ModelsCard grid={3} title="DODGE CHARGER HELLCAT WIDEBODY"
                        image="Charger-GT-PBM-landscape_thumb@2x.jpg"
            />
          </Row>
        </div>

        <Title>ODKRYJ NA NOWO SWOJEGO CHARGERA</Title>

        <ImageAngle style={{position: "relative", zIndex: "10"}}>
          <Image fileName="charger_odkryj_banner@2x.png" alt="Odkryj na nowo swojego chargera"/>
        </ImageAngle>

        <BlackGrid className={`first`}>
          <Title color="white">Z ZEWNĄTRZ</Title>

          <div className="container">
            <Image fileName="charger_outside_banner@2x.png" alt="Z zewnętrz"/>
          </div>
        </BlackGrid>

        <div className="container">
          <Title>EPICKA wydajność I WYTRZYMAŁOŚĆ</Title>

          <Text style={{maxWidth: "80rem"}}>
            Charger SRT® Hellcat Widebody jest liderem pod względem wydajności i wzornictwa. <br/>
            Dodge Charger to najszybszy i najmocniejszy seryjnie produkowany sedan na świecie.
          </Text>
        </div>

        <RowAngle>
          <img src={"/images/charger_wydajnosc@2x.png"} alt={"Dodge Charger"}/>

          <div className="content">
            <strong>Charger SRT Hellcat Widebody</strong> <br/>
            jest liderem wydajności i designu, który określa prawdziwe samochody z kategorii muscle car
          </div>
        </RowAngle>

        <div className="container">
          <Title>ZAWSZE W ODPOWIEDNIM MOMENCIE</Title>

          <Text style={{maxWidth: "80rem"}}>
            Niezależnie od tego, czy jest to zwykłe zwalnianie, czy gwałtowne hamowanie masz pewność, że robisz to odpowiednio.
          </Text>

          <BlockRow>
            <BlockCard grid={3}>
              <Image fileName={"2020-dodge-charger-performance-4-piston-brakes@2x.png"} alt={"Czterotłoczkowy układ hamulcowy Brembo"}/>
              <CardTitle>WYSOKOWYDAJNE CZTEROTŁOCZKOWE HAMULCE BREMBO</CardTitle>
              <CardLine/>
              <CardText>
                Czterotłoczkowy układ hamulcowy Brembo zapewnia lepszą kontrolę kierowania w ekstremalnych warunkach hamowania lub poślizgu.
              </CardText>
            </BlockCard>

            <BlockCard grid={3}>
              <Image fileName={"2020-dodge-charger-performance-6-piston-brakes@2x.png"} alt={"Sześciotłoczkowy układ hamulcowy Brembo"}/>
              <CardTitle>HAMULCE O WYSOKIEJ WYDAJNOŚCI BREMBO SZEŚCIOTŁOCZKOWE</CardTitle>
              <CardLine/>
              <CardText>
                Dzięki sześciotłoczkowemu układowi hamulcowemu Brembo, kierowcy doświadczają inspirowanej wyścigami siły hamowania i kontroli na otwartej drodze.
              </CardText>
            </BlockCard>

            <BlockCard grid={3}>
              <Image fileName={"2020-dodge-charger-performance-caliper-colors@2x.png"} alt={"Czterotłoczkowy układ hamulcowy Brembo oferuje czerwone lub czarne zaciski"}/>
              <CardTitle>RÓŻNORODNE KOLORY <br/>
                ZACISKÓW</CardTitle>
              <CardLine/>
              <CardText>
                Czterotłoczkowy układ hamulcowy Brembo oferuje czerwone lub czarne zaciski, które stanowią dodatkowe podkreślenie wyglądu. W przypadku sześciotłoczkowego układu hamulcowego Brembo możesz wybierać spośród czerwonego, czarnego, brązowego lub pomarańczowego koloru.
              </CardText>
            </BlockCard>
          </BlockRow>
        </div>

        <BlackGrid>
          <Title color="white">EFEKTYWNOŚĆ</Title>

          <div className="container" >
            <Text color="#a7a7a7" style={{maxWidth: "80rem", marginBottom: "4rem"}}>
              Efektywność to siła pochodząca z wnętrza. Gdy ryczący silnik porusza się po ulicach nic nie jest w stanie go zatrzymać.
            </Text>

            <Image fileName="charger-efektywnosc@2x.png" alt="Efektywność Chargera"/>
          </div>
        </BlackGrid>

        <div className="container">
          <Title>GENIALNOŚĆ DOPASOWANIA</Title>

          <Text style={{maxWidth: "80rem"}}>
            Nasza najnowocześniejsza, ośmiobiegowa automatyczna skrzynia biegów TorqueFlite® wraz z dostępnym trybem sportowym i dostępną przekładnią łopatkową na kierownicy zapewnia światowej klasy precyzję.
          </Text>
        </div>

        <BlockGrey style={{paddingBottom: "0"}}>
          <Title>PERFEKCJA TKWI W PREZYCJI</Title>

          <div className="container">
            <Text maxWidth={"60rem"} style={{marginBottom: '4rem'}}>
              Wybierz system zawieszenia <br/>
              odpowiedni do twoich potrzeb
            </Text>

            <ImageAngle className={`translate-bottom`}>
              <Image fileName={"2020-dodge-charger-performance-perfection-in-precision@2x.png"} alt={"Wybierz system zawieszenia"}/>
            </ImageAngle>
          </div>
        </BlockGrey>

        <div className="container">
          <BlockRow>
            <BlockCard grid={3}>
              <CardTitle>
                SPEKTAKULARNE <br/> ZAWIESZENIE
              </CardTitle>
              <CardLine />
              <BlockCardText>
                Sportowe zawieszenie jest standardem w modelach Charger GT oraz R/T. Obejmuje sprężyny, wahacze i wysokowydajne amortyzatory Bilstein®. Obniżone o pół cala, działa w parze z aktywnym układem kierowniczym, aby uzyskać maksymalnie sportowe wrażenia z jazdy.
              </BlockCardText>
            </BlockCard>

            <BlockCard grid={3}>
              <CardTitle>
                ZAWIESZENIE <br/> O WYSOKIEJ WYDAJNOŚCI
              </CardTitle>
              <CardLine />
              <BlockCardText>
                Zawieszenie o wysokiej wydajności jest standardem w Scat Pack oraz  jest dołączone do opcjonalnego pakietu  Performance Handling  w Charger GT i R/T. Opiera się ono na sportowym zawieszeniu z wysokowydajnymi amortyzatorami Bilstein®. To wszystko, by poprawić równowagę, zdolność pokonywania zakrętów i szybkość reakcji układu kierowniczego.
              </BlockCardText>
            </BlockCard>

            <BlockCard grid={3}>
              <CardTitle>
                ZAWIESZENIE <br/> WIDEBODY COMPETITION
              </CardTitle>
              <CardLine />
              <BlockCardText>
                Standardowo dla Charger SRT® Hellcat Widebody oraz opcjonalnie dla zestawu Widebody w Scat Pack, zawieszenie obejmuje aktywny, trzy-trybowy system amortyzacji, który umożliwia kierowcom wybór różnych ustawień zawieszenia w zależności od preferencji jazdy. Tryb automatyczny zapewnia równowagę sztywności i komfortu podczas typowej codziennej jazdy. Tryb Sport zapewnia lepsze prowadzenie. Tryb TRACK zapewnia maksymalną radość prowadzenia z najsztywniejszym układem zawieszenia.
              </BlockCardText>
            </BlockCard>
          </BlockRow>
        </div>

        <BlackGrid style={{paddingBottom: "8rem"}}>
          <Title color="white">WNĘTRZE</Title>

          <ImageAngle className={`m0 container`}>
            <Image fileName="Schermata-2019-12-04-alle-00.23.38@2x.png" alt="Chargera wnętrze"/>
          </ImageAngle>
        </BlackGrid>

        <BlockGrey style={{marginTop: "0"}}>
          <Row className={`container`}>
            <BlockRowImage className={`outside`}>
              <img src={"/images/charger_tryby_sterowania@2x.png"} alt={"Dodge Charger"}/>
            </BlockRowImage>
            <BlockRowContent>
              <BlockTitle>
                TRYBY STEROWANIA <br/>
                KIEROWCY
              </BlockTitle>
              <CardLine/>
              <BlockText>
                Masz możliwość doboru swoich preferencji dzięki trybom sterowania wybieranym przez kierowcę w CHarger SRT® Hellcat Widebody i opcjonalnie w Scat Pack. Inspirowane wyścigami technologie, które mają na celu jeszcze bardziej zwiększyć wydajność, są standardem w modelu 2020 SRT Hellcat Widebody. Obejmują Race Cooldown, Line Lock, Launch Control oraz Launch Assist.
              </BlockText>
            </BlockRowContent>
          </Row>
        </BlockGrey>

        <div className="container">
          <Title>WYBIERZ SWÓJ TRYB</Title>

          <Text style={{maxWidth: "80rem"}}>
            Im większa kontrola, tym lepszy napęd. Elektryczne wspomaganie kierownicy jest standardem we wszystkich modelach Charger. Charger SRT® pozwala wybierać spośród trzech trybów sterowania wybieranych przez kierowcę: Street, Sport oraz Track.
          </Text>

          <ImageAngle className={"block-image"}>
            <Image fileName={"charger_tryb@2x.png"} alt={"Dodge Charger"}/>
          </ImageAngle>

          <BlockRow>
            <BlockCard grid={4}>
              <CardTitle>TRZY TRYBY: <br/>
                STREET, SPORT I TRACK</CardTitle>
              <CardLine/>
              <CardText>
                Przejdź do modułu z wybieranymi trybami jazdy. Standard w Charger SRT® Hellcat Widebody i opcjonalny dla Scat Pack.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <CardTitle>ZAWSZE <br/>
                POD KONTROLĄ</CardTitle>
              <CardLine/>
              <CardText>
                Przejmij pełną kontrolę korzystając z dostępnych metalowych manetek zmiany biegów.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <CardTitle>WYGODNA br
                REGULACJA </CardTitle>
              <CardLine/>
              <CardText>
                Jeden przycisk, aby dostosować położenie kierownicy za pomocą teleskopowej kolumny kierownicy oraz mechanizmu jej pochylania.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <CardTitle>PODGRZEWANA <br/>
                SKÓRZANA KIEROWNICA</CardTitle>
              <CardLine/>
              <CardText>
                Nie tylko siedzenia muszą być wygodne. Pozwól odpocząć swoim dłoniom na skórzanej kierownicy z opcją ogrzewania. Teraz pozostało już tylko delektować się jazdą.
              </CardText>
            </BlockCard>
          </BlockRow>
        </div>

        <div className="container">
          <Title>KOMFORT</Title>

          <Text style={{maxWidth: "80rem"}}>
            Z cyfrowym wyświetlaczem informacji kierowcy, przyciskiem Start/Stop, dostępnym ekranem multimedialnym Uconnect® 4C NAV oraz automatyczną skrzynią biegów, Dodge Charger wprowadza nową jakość komfortu jazdy.
          </Text>

          <BlockRow style={{marginBottom: "4rem"}}>
            <BlockCard grid={4}>
              <Image fileName={"2020-dodge-charger-interior-cluster-display@2x.png"} alt={"Dodge Charger"}/>
              <CardTitle>
                DIGITAL CLUSTER <br/> DISPLAY
              </CardTitle>
              <CardLine/>
              <CardText>
                7-calowy wyświetlacz zapewnia natychmiastowe informacje o wydajności pracy silnika, dostępnej nawigacji i statystykach pojazdu. Łatwo dostosuj wyświetlacz za pomocą elementów sterujących na kierownicy.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <Image fileName={"2020-dodge-charger-interior-push-button@2x.png"} alt={"Dodge Charger"}/>
              <CardTitle>
                PRZYCISK <br/>
                START / STOP
              </CardTitle>
              <CardLine/>
              <CardText>
                Silnik możesz uruchomić bezproblemowo i wygodnie naciskając pedał hamulca oraz przycisk Start / Stop.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <Image fileName={"2020-dodge-charger-interior-4c-nav@2x.png"} alt={"Dodge Charger"}/>
              <CardTitle>
                UCONNECT® <br/> 4C NAV
              </CardTitle>
              <CardLine/>
              <CardText>
                Dostępne funkcje Uconnect® 4C NAV, w tym pomoc drogowa, zdalne uruchomienie pojazdu i inne..
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <Image fileName={"2020-dodge-charger-interior-performance-shifter@2x.png"} alt={"Dodge Charger"}/>
              <CardTitle>
                PRZEKŁADNIA <br/> WYDAJNOŚCI
              </CardTitle>
              <CardLine/>
              <CardText>
                Ośmiobiegowa automatyczna skrzynia biegów TorqueFlite®, wyjątkowa w swojej klasie, ma w pełni elektroniczny mechanizm zmiany biegów.
              </CardText>
            </BlockCard>
          </BlockRow>

          <Image fileName={"komfort-1@2x.png"} alt={"Dodge Charger"}/>

          <Title>PRZESTRZEŃ</Title>

          <Text style={{maxWidth: "80rem"}}>
            Od wnętrza po bagażnik. <br/>
            Wystarczająco miejsca dla najbardziej wymagających pasażerów.
          </Text>

          <BlockRow>
            <BlockCard grid={4}>
              <Image fileName={"2020-dodge-charger-interior-5-passenger-seating@2x.png"} alt={"Dodge Charger"}/>
              <CardTitle>
                MIEJSCE <br/> DLA 5 PASAŻERÓW
              </CardTitle>
              <CardLine/>
              <CardText>
                Zabierz swoich bliskich w komfortową podróż dzięki wygodnym fotelom.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <Image fileName={"2020-dodge-charger-interior-folding-rear-seats@2x.png"} alt={"Dodge Charger"}/>
              <CardTitle>
                SIEDZENIA TYLNE <br/>
                SKŁADANE 60/40
              </CardTitle>
              <CardLine/>
              <CardText>
                Gdy potrzebujesz wyjątkowo dużo miejsca dla swoich bagaży, możesz złożyć tylne siedzenia składając jeden fotel lub podwójną kanapę.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <Image fileName={"2020-dodge-charger-interior-space-center-console@2x.png"} alt={"Dodge Charger"}/>
              <CardTitle>
                KONSOLA WEWNĘTRZNA <br/> I CENTRUM
              </CardTitle>
              <CardLine/>
              <CardText>
                Jazda w ścisku to nie sposób na przyjemną podróż. Kabina o powierzchni do 3 metrów sześciennych zapewnia duży komfort każdemu pasażerowi.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <Image fileName={"2020-dodge-charger-interior-trunck-storage@2x.png"} alt={"Dodge Charger"}/>
              <CardTitle>
                POJEMNY <br/> BAGAŻNIK
              </CardTitle>
              <CardLine/>
              <CardText>
                Zabierz ze sobą wszystko podczas jednej podróży. Załaduj wszystko, co chcesz do bagażnika o powierzchni aż 0,5 m³.
              </CardText>
            </BlockCard>
          </BlockRow>

          <Title>Dźwięk</Title>

          <Image fileName={"charger_dzwiek@2x.png"} alt={"Dodge Charger"}/>

          <BlockRow>
            <BlockCard className={`card-2`}>
              <CardTitle>
                GŁOŚNIKI
              </CardTitle>
              <CardLine/>
              <CardText>
                Zwiększ moc dźwięku dzięki sześciogłośnikowemu systemowi Alpine®, dziewięciogłośnikowemu systemowi Alpine Audio lub najwyższej opcji 19-głośnikowego systemowi audio Harman Kardon®.
              </CardText>
            </BlockCard>

            <BlockCard className={`card-2`}>
              <CardTitle>
                ANDROID AUTO ™ I APPLE CARPLAY®
              </CardTitle>
              <CardLine/>
              <CardText>
                Uzyskaj dostęp do wszystkiego, czego potrzebujesz, łącząc się z Apple CarPlay® za pomocą iPhone'a®. Aby korzystać z aplikacji za pośrednictwem ekranu dotykowego Uconnect®, telefony z Androidem mogą łączyć się za pomocą Android Auto ™.
              </CardText>
            </BlockCard>
          </BlockRow>
        </div>


        <BlackGrid>
          <Title color="white">BEZPIECZEŃSTWO & OCHRONA</Title>

          <ImageAngle className={`container`}>
            <Image fileName={"charger_bezpieczenstwo_banner@2x.png"} alt="Charger - bezpieczeństwo i ochrona"/>
          </ImageAngle>
        </BlackGrid>

        <div className="container">
          <Title>BĄDŹ PRZYGOTOWANY</Title>

          <Text style={{maxWidth: "80rem"}}>
            Świadomość jest najważniejszą częścią prowadzenia pojazdu. Dodge Charger oferuje kilka dostępnych funkcji bezpieczeństwa, które dokładnie monitorują otoczenie i ostrzegają o zbliżającym się niebezpieczeństwie.
          </Text>

          <ImageAngle className={`container`}>
            <Image fileName={"2020-dodge-charger-safety-know-whats-coming@2x.png"} alt={"Dodge Charger"}/>
          </ImageAngle>

          <BlockRow>
            <BlockCard className={`card-5`}>
              <CardTitle>
                AUTOMATYCZNE REFLEKTORY
              </CardTitle>
              <CardLine/>
              <CardText>
                Światła drogowe przełączają się automatycznie na światła mijania, gdy wykryją reflektory poprzedzających pojazdów.
              </CardText>
            </BlockCard>

            <BlockCard className={`card-5`}>
              <CardTitle>
                MONITOROWANIE <br/>
                MARTWEGO POLA
              </CardTitle>
              <CardLine/>
              <CardText>
                Dostępny system monitorowania martwego pola pomaga zmienić pas, ostrzegając cię, gdy obcy pojazd wjedzie do jednej z twoich stref.
              </CardText>
            </BlockCard>

            <BlockCard className={`card-5`}>
              <CardTitle>
                AKTYWNY <br/>
                TEMPOMAT
              </CardTitle>
              <CardLine/>
              <CardText>
                Automatycznie określ prędkość swojego Charger’a oraz odległość od poprzedzających pojazdów.
              </CardText>
            </BlockCard>

            <BlockCard className={`card-5`}>
              <CardTitle>
                TYLNA KAMERA COFANIA PARKVIEW®
              </CardTitle>
              <CardLine/>
              <CardText>
                Podczas cofania tylna kamera cofania ParkView® wyświetla na ekranie dotykowym Uconnect® to, co dzieje się za pojazdem. Linie kierunku pomagają w manewrowaniu na parkingach lub w wąskich obszarach.
              </CardText>
            </BlockCard>

            <BlockCard className={`card-5`}>
              <CardTitle>
                OSTRZEŻENIE PRZED KOLIZJĄ
              </CardTitle>
              <CardLine/>
              <CardText>
                System ostrzeżenia przed kolizją przesyła sygnał dźwiękowy oraz wizualny. W razie potrzeby również zatrzyma pojazd.
              </CardText>
            </BlockCard>
          </BlockRow>
        </div>

        <BlockGrey>
          <Title>TARCZA BEZPIECZEŃSTWA</Title>

          <div className="container">
            <Image fileName={"2020-dodge-charger-safety-safety-shield@2x.png"} alt="Dodge Charger"/>
          </div>
        </BlockGrey>

        <div className="container">
          <BlockRow>
            <BlockCard grid={4}>
              <CardTitle>
                SOLIDNA <br/> RAMA
              </CardTitle>
              <CardLine/>
              <CardText>
                Solidna rama zbudowana z wysokowytrzymałej stali oraz wytrzymały dach skutecznie chroni kabinę pasażerską w razie wypadku.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <CardTitle>
                OSŁONY <br/> DRZWI BOCZNYCH
              </CardTitle>
              <CardLine/>
              <CardText>
                Stalowe belki o wysokiej wytrzymałości są przyspawane do konstrukcji drzwi bocznych pojazdu i połączone ze wzmocnieniami w konstrukcji samochodu, aby zapewnić dynamiczną ochronę przed bocznym uderzeniem.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <CardTitle>
                PODUSZKI <br/> POWIETRZNE
              </CardTitle>
              <CardLine/>
              <CardText>
                Zaawansowane, wielostopniowe przednie poduszki powietrzne, pełnowymiarowe boczne poduszki powietrzne, montowane z przodu boczne poduszki powietrzne miednicy i klatki piersiowej oraz boczna poduszka powietrzna chroniąca kolana po stronie kierowcy są standardem.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <CardTitle>
                AKTYWNE ZAGŁÓWKI <br/> PRZEDNICH FOTELI
              </CardTitle>
              <CardLine/>
              <CardText>
                W przypadku uderzeń w tył pojazdu, aktywne zagłówki pomagają zmniejszyć ryzyko odniesienia obrażeń przez kierowcę i pasażera z przodu.
              </CardText>
            </BlockCard>
          </BlockRow>
        </div>

        <div className="container">
          <Title>TECHNIKA, KTÓRA CHRONI</Title>

          <div className="container">
            <Image fileName={"2020-dodge-charger-safety-tech@2x.png"} alt="Dodge Charger"/>
          </div>

          <BlockRow>
            <BlockCard grid={4}>
              <CardTitle>
                KONTROLA TRAKCJI W KAŻDYCH WARUNKACH
              </CardTitle>
              <CardLine/>
              <CardText>
                Kontrola trakcji pomaga utrzymać przyczepność i stabilność, przykładając ciśnienie hamowania do ślizgających się kół i zmniejszając moc silnika. Jest to konieczne, aby pomóc oponom odzyskać przyczepność.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <CardTitle>
                ASYSTENT STARTU NA WZNIESIENIU
              </CardTitle>
              <CardLine/>
              <CardText>
                Gdy jesteś na wzniesieniu, Hill Start Assist utrzyma ciśnienie hamowania przez krótki czas między zwolnieniem pedału hamulca i naciśnięciem pedału przyspieszenia, zapobiegając stoczeniu się pojazdu.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <CardTitle>
                ELEKTRONICZNA KONTROLA STABILNOŚCI
              </CardTitle>
              <CardLine/>
              <CardText>
                Pokonaj prawie każdy stan drogi dzięki Electronic Stability Control (ESC), który monitoruje twój układ kierowniczy i ruch pojazdu, aby pomóc ci zachować kontrolę jazdy.
              </CardText>
            </BlockCard>

            <BlockCard grid={4}>
              <CardTitle>
                AKTYWNY TEMPOMAT <br/> Z FUNKCJĄ STOP
              </CardTitle>
              <CardLine/>
              <CardText>
                Dostępny adaptacyjny tempomat z funkcją Stop (ACC STOP) automatycznie dostosowuje prędkość do poprzedzającego pojazdu, aby pomóc w utrzymaniu ustawionego dystansu za pomocą przepustnicy i ograniczonego hamowania.
              </CardText>
            </BlockCard>
          </BlockRow>
        </div>

        <div className="container">
          <Title>BĄDŹ PEWIEN, ŻE JESTEŚ BEZPIECZNY</Title>

          <Text style={{maxWidth: "80rem"}}>
            Naszym priorytetem jest uchronić Cię przed niebezpieczeństwem. <br/>
            Prowadź z pewnością, że jesteś w dobrych rękach.
          </Text>

          <ImageAngle>
            <Image fileName={"2020-dodge-charger-safety-backup-camera@2x.png"} alt={"Dodge Charger"}/>
          </ImageAngle>
        </div>

        <BlackGrid>
          <Title color="white">hamuj śmiało</Title>

          <ImageAngle className={`container`}>
            <Image fileName={"charger_hamuj@2x.png"} alt={"Dodge Charger"} />
          </ImageAngle>
        </BlackGrid>

        <div className="container">
          {charger.map(model => (
            <Card key={model.id}>
              <CardImg>
                <Image fileName={model.image} alt={model.title}/>
              </CardImg>
              <CardContent>
                <CardTitle>{model.title}</CardTitle>
                <CardLine/>

                <CardList>
                  {model.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </CardList>
              </CardContent>
            </Card>
          ))}
        </div>

      </main>
    </Layout>
  )
}

export default Charger